import { CdkConnectedOverlay, ConnectedPosition } from '@angular/cdk/overlay';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { LayoutService } from 'src/app/shared/service/layout.service';
import { PopupService } from 'src/app/shared/service/popup.service';
import { SearchGameDialogComponent } from '../dialog/dialog.component';

@UntilDestroy()
@Component({
  selector: '[app-search-game]',
  templateUrl: './search-game.component.html',
  styleUrls: ['./search-game.component.scss'],
})
export class SearchGameComponent implements OnInit {
  public constructor(
    private _layout: LayoutService,
    private _popupService: PopupService,
  ) {}

  /**为你推荐需要的codes */
  @Input() labelCodes: string[] = [];

  @ViewChild('trigger') trigger!: ElementRef;

  @ViewChild(CdkConnectedOverlay, { static: false }) cdkConnectedOverlay?: CdkConnectedOverlay;

  public isOpen: boolean = false;

  public positions: ConnectedPosition[] = [{ originX: 'start', originY: 'top', overlayX: 'start', overlayY: 'top' }];

  public triggerRect: any;

  public searchValue: string = '';

  // 切换显示
  public toggle() {
    // 打开时，取得触发元素的宽度信息
    if (!this.isOpen) this.triggerRect = this.trigger?.nativeElement?.getBoundingClientRect();
    this.isOpen = !this.isOpen;
  }

  public openSearchDialog() {
    this._popupService.open(SearchGameDialogComponent, {
      disableClose: true,
      speed: 'faster',
      hasBackdrop: false,
      autoFocus: false,
      inAnimation: 'fadeInRight',
      outAnimation: 'fadeOutRight',
      isFull: true,
      panelClass: 'mask-penetration',
    });
  }

  public openSearch() {
    const isH5 = this._layout.isH5$.value;
    if (isH5) {
      this.openSearchDialog();
    } else {
      this.toggle();
    }
  }

  public ngOnInit(): void {
    this._layout.page$.pipe(untilDestroyed(this)).subscribe(() => {
      this.isOpen = false;
      this.cdkConnectedOverlay?.overlayRef?.detach();
    });

    // 订阅页面缩放，用于跟随触发元素宽度自动设置弹出内容的宽度
    this._layout.resize$.pipe(untilDestroyed(this)).subscribe(() => {
      if (this.isOpen) this.triggerRect = this.trigger?.nativeElement?.getBoundingClientRect();
    });
  }
}
